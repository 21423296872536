import Vue from "vue";
import Vuetify from "vuetify/lib/";

Vue.use(Vuetify);
const opts = {};

export default new Vuetify({
  opts,
  options: {
    customProperties: true,
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#373737",
        secondary: "#F0F6F0",
        accent: "#61C230",
        cardBackground: "#F4F4F4",
        background: "#F4F4F4",
      },
    },
  },
});
