<template>
  <v-app>
    <div v-if="isLoading">
      Loading...
    </div>
    <div v-else>
      <NavBar @localeChange="loadLocaleMessages" />
      <div>
        <router-view />
      </div>
    </div>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar"
import EventBus from "@/EventBus"
export default {
  components: { NavBar },
  data: () => ({
    isLoading: true
  }),
  mounted() {
    EventBus.$on("i18n-load-start", () => (this.isLoading = true))
    EventBus.$on("i18n-load-complete", () => (this.isLoading = false))
  }
}
</script>
<style scoped>
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #F4F4F4;
}
</style>

