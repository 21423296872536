export default {
  en: "English",
  bo: "བོད་སྐད།",
  my: "မြန်မာ",
  zh_CN: "普通话",
  zh_HK: "粤语部",
  km: "ខ្មែរ",
  ko: "한국의",
  lo: "ລາວ",
  ug: "باش بەت",
  ug_cyrillic: "Уйғурчә",
  ug_latin: "Uyghurche",
  vi: "Tiếng Việt",
};
