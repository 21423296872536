<template>
  <svg
    id="web"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
  >
    <path
      d="M17.985,3A15,15,0,1,0,33,18,14.993,14.993,0,0,0,17.985,3Zm10.4,9H23.955a23.474,23.474,0,0,0-2.07-5.34A12.044,12.044,0,0,1,28.38,12ZM18,6.06A21.13,21.13,0,0,1,20.865,12h-5.73A21.13,21.13,0,0,1,18,6.06ZM6.39,21a11.733,11.733,0,0,1,0-6h5.07a24.773,24.773,0,0,0-.21,3,24.773,24.773,0,0,0,.21,3Zm1.23,3h4.425a23.474,23.474,0,0,0,2.07,5.34A11.98,11.98,0,0,1,7.62,24Zm4.425-12H7.62a11.98,11.98,0,0,1,6.495-5.34A23.474,23.474,0,0,0,12.045,12ZM18,29.94A21.13,21.13,0,0,1,15.135,24h5.73A21.13,21.13,0,0,1,18,29.94ZM21.51,21H14.49a22.069,22.069,0,0,1-.24-3,21.877,21.877,0,0,1,.24-3h7.02a21.877,21.877,0,0,1,.24,3A22.069,22.069,0,0,1,21.51,21Zm.375,8.34A23.474,23.474,0,0,0,23.955,24H28.38a12.044,12.044,0,0,1-6.495,5.34ZM24.54,21a24.773,24.773,0,0,0,.21-3,24.773,24.773,0,0,0-.21-3h5.07a11.733,11.733,0,0,1,0,6Z"
      transform="translate(-3 -3)"
    />
  </svg>
</template>

<script>
export default {
    name: "WebIcon"
}
</script>

<style>
#web {
    max-width: 24px;
    height: 100%;
    fill: #ffff;
}
</style>
