<template>
  <div class="home">
    <MainContent :class="$i18n.locale" />
  </div>
</template>

<script>
import MainContent from "@/components/MainContent.vue";

export default {
  name: "HomeView",
  components: {
    MainContent,
  },
};
</script>

<style>
.home {
  background-color: var(--bcg);
}
</style>
