<template>
  <v-container>
    <v-card
      flat
      title
      class="mx-6 d-flex flex-column justify-center align-center"
      height="85vh"
    >
      <div
        class="d-flex justify-center my-2 mx-auto"
        style="max-width: 400px"
        width="80%"
      >
        <TextBubble />
      </div>
      <subtitle-1 class="text-center mb-11 mt-2 px-10" style="max-width: 620px">
        {{ $t("home.latest_news", { value: "test" }) }}
      </subtitle-1>
      <div class="text-center mt-11 mb-2">
        <v-btn x-large color="accent">
          <a
            class="white--text text-decoration-none text-capitalize"
            :href="'https://report.rfasafedrop.org/#/?lang=' + $i18n.locale"
          >
            {{ $t("home.submit") }}
          </a>
        </v-btn>
      </div>
    </v-card>
    <p class="text-center px-3">
      <a href="https://www.torproject.org/" class="sm">{{ $t("home.tor") }}</a>
    </p>

    <!-- mission text -->
    <v-container fluid ma-0 pa-0>
      <v-layout>
        <v-flex md12 full-width>
          <v-card
            color="primary"
            dark
            flat
            tile
            elevation="0"
            class="pa-10 ma-0 pa-xs-5"
          >
            <v-container style="max-width: 620px">
              <div
                class="italic text-white mb-3"
                :class="$i18n.locale"
                style="font: var(--quote-font); font-style: normal;"
              >
                {{ $t("home.FreePress") }}
              </div>
              <v-divider width="25%" />
              <v-spacer />
              <div class="text" :class="$i18n.locale">
                {{ $t("home.MoreText1") }}
                <br />
                <br />
                {{ $t("home.MoreText2") }}
              </div>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <!-- how it works -->
    <div class="text-center mt-16 pt-16 mb-7">
      <HalfShieldIcon />
      <h2 :class="$i18n.locale">
        {{ $t("home.HowItWorks") }}
      </h2>
    </div>

    <v-container fluid class="fill-height">
      <v-row
        class="align-stretch d-flex flex-row justify-space-around flex-xs-column"
      >
        <v-col class="d-flex justify-center xl">
          <v-card
            height="100%"
            min-width="250"
            max-width="344"
            class="text-center pa-8"
          >
            <LockIcon />
            <v-card-title class="text-wrap">
              <v-spacer>
                <h3>{{ $t("home.EncryptedComms") }}</h3>
              </v-spacer>
            </v-card-title>
            <v-card-text>{{ $t("home.EncryptedText") }}</v-card-text>
          </v-card>
        </v-col>
        <v-col class="d-flex justify-center mx-lg-6">
          <v-card
            height="100%"
            min-width="250"
            max-width="344"
            class="text-center pa-8 flex-grow-1"
          >
            <EyeOffIcon />
            <v-card-title>
              <v-spacer>
                <h3>{{ $t("home.Anonymity") }}</h3>
              </v-spacer>
            </v-card-title>
            <v-card-text>{{ $t("home.AnonymityText") }}</v-card-text>
          </v-card>
        </v-col>
        <v-col class="d-flex justify-center xl">
          <v-card
            height="100%"
            min-width="250"
            max-width="344"
            class="text-center pa-8"
          >
            <ShieldIcon />
            <v-card-title>
              <v-spacer>
                <h3>{{ $t("home.Security") }}</h3>
              </v-spacer>
            </v-card-title>
            <v-card-text>{{ $t("home.SecurityText") }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- FAQ-->
    <v-container>
      <div class="text-center mt-16 pt-16 mb-3 px-2">
        <FAQIcon />
        <h2 :class="$i18n.locale">
          {{ $t("home.FAQ") }}
        </h2>
      </div>
      <v-expansion-panels class="mx-auto pb-16" tile flat>
        <v-expansion-panel>
          <v-expansion-panel-header
            class="py-6 px-1 px-sm-5"
            :class="$i18n.locale"
          >
            {{ $t("home.FAQQuestion1") }}
            <template #actions>
              <PlusIcon />
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="answer px-sm-4">
            {{ $t("home.FAQAnswer1") }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            class="py-6 px-1 px-sm-5"
            :class="$i18n.locale"
          >
            {{ $t("home.FAQQuestion2") }}
            <template #actions>
              <PlusIcon />
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="answer px-sm-4">
            <p>{{ $t("home.FAQAnswer2") }}</p>
            <p>{{ $t("home.IncreasePrivacy") }}</p>
            <ul id="list">
              <li>{{ $t("home.IncreasePrivacy4") }}</li>
              <li>{{ $t("home.IncreasePrivacy1") }}</li>
              <li>{{ $t("home.IncreasePrivacy2") }}</li>
              <li>{{ $t("home.IncreasePrivacy3") }}</li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            class="py-6 px-1 px-sm-5"
            :class="$i18n.locale"
          >
            {{ $t("home.FAQQuestion3") }}
            <template #actions>
              <PlusIcon />
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="answer px-sm-4"
            :class="$i18n.locale"
          >
            {{ $t("home.FAQAnswer3") }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            class="py-6 px-1 px-sm-5"
            :class="$i18n.locale"
          >
            {{ $t("home.FAQQuestion4") }}
            <template #actions>
              <PlusIcon />
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="answer" :class="$i18n.locale">
            {{ $t("home.FAQAnswer4") }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-container>
</template>

<script>
import TextBubble from "/public/assets/TextBubble.vue";

export default {
  components: {
    FAQIcon: () => import("/public/assets/icons/FAQIcon"),
    LockIcon: () => import("/public/assets/icons/lockIcon"),
    EyeOffIcon: () => import("/public/assets/icons/eyeOffIcon"),
    HalfShieldIcon: () => import("/public/assets/icons/halfShieldIcon"),
    ShieldIcon: () => import("/public/assets/icons/shieldIcon"),
    PlusIcon: () => import("/public/assets/icons/plusIcon"),
    TextBubble,
  },
};
</script>

<style scoped>
.theme--light.v-card,
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: var(--v-theme-primary);
}

.v-card__text, .v-card__title, .v-btn.v-size--x-large, .v-expansion-panel-header {
  font-size: inherit!important;
  line-height: inherit;
}
.v-btn {
  letter-spacing: normal;
}
ul#list {
  list-style: none;
}

ul#list > li {
  text-indent: -5px;
}

ul#list > li:before {
  content: "— ";
  text-indent: -5px;
}

.v-spacer {
  width: 100%;
}

.v-expansion-panel-header {
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid grey;
}

.v-expansion-panels {
  width: 75vw;
  max-width: 1020px;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: var(--green);
}

button a {
  text-decoration: none;
  color: white;
}
</style>
