import Vue from "vue";
import VueRouter from "vue-router";// eslint-disable-next-line
import HomeView from "../views/HomeView.vue";
import RootView from "./RootView";
import i18n, { loadLocaleMessagesAsync } from "@/i18n";
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
} from "@/util/i18n/document";

Vue.use(VueRouter);

function lazyView(view) {
  return () =>
    import(
      /* webpackChunkName: "lazy-view-[request]" */ `../views/${view}.vue`
    );
}

const { locale } = i18n;

const routes = [
  {
    path: "/",
    redirect: locale,
  },
  {
    path: "/:locale",
    component: RootView,
    children: [
      {
        path: "",
        name: "HomeView",
        component: lazyView("HomeView"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.params.locale === from.params.locale) {
    next();
    return;
  }

  const { locale } = to.params;

  loadLocaleMessagesAsync(locale).then(() => {
    setDocumentLang(locale);

    setDocumentDirectionPerLocale(locale);
  });

  next();
});

export default router;
