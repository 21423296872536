<template>
  <div class="locale-switcher d-flex align-center justify-center">
    <WebIcon class="me-2" />
    <select
      style="color: white"
      :value="$i18n.locale"

        :class="$i18n.locale"
      @change.prevent="changeLocale"
    >
      <option
        v-for="locale in locales"
        :key="locale.code"
        class="pa-3 options"
        :value="locale.code"
      >
        {{ locale.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales";
import WebIcon from "/public/assets/icons/webIcon.vue";

export default {
  components: { WebIcon },
  data: () => ({ locales: getSupportedLocales() }),
  methods: {
    changeLocale(e) {
      const locale = e.target.value;
      this.$router.push(`/${locale}`);
    },
  },
};
</script>

<style>
.locale-switcher {
  background-color: var(--grey);
  border-radius: 4px;
  height: 40px;
  width: 150px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px !important;
  line-height: 2 !important;
}

option.options {
  background-color: var(--grey);
  padding: 10px !important;
  font-size: 18px !important;
  line-height: 35px !important;
}
</style>
