<template>
  <v-app-bar dense flat height="60">
    <div class="d-flex align-center">
      <v-img
        alt="Logo"
        class="shrink mr-2"
        contain
        src="../../public/assets/logo.svg"
        width="70"
      />
    </div>
    <v-spacer />
    <LocaleSwitcher />
  </v-app-bar>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  components: { LocaleSwitcher },
};
</script>
